import { Cookies } from 'react-cookie';
const cookies = new Cookies();
export const languageType = () => {
  const myCookieValue = cookies.get('language');
  let languageCokieValue = myCookieValue || 'el';
  // if (typeof window != 'undefined') {
  //   languageCokieValue =
  //     document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'el';
  // } else {
  //   languageCokieValue = 'el';
  // }
  // console.log('faisal', languageCokieValue);
  return languageCokieValue;
};
